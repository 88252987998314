<template>
  <div id="roles" class="h-100" @click="closeDetail">
    <SetupTitle :name="t('setup.role.title')" />

    <div class="setupMain">
      <div id="toolBar">
        <CommonButtonAdd
          :name="t('setup.role.addBtn')"
          class="mb-4"
          @click="createRoleFn()"
        />
      </div>
      <div class="tableStyle">
        <SetupTeamRoleTable
          :table-data="rolesList"
          @emitInfo="emitInfo"
          @delRole="delRole"
          @showRoleDetailBlock="showRoleDetailBlock"
        />
      </div>
      <!-- 新增角色 -->
      <LazySetupTeamRoleAddDlg
        :is-show="onAddRole"
        :dialog-info="dialogInfo"
      />
      <!-- 添加成員 -->
      <LazySetupTeamRoleAddAgentDlg
        :is-show="onAddAgent"
        :dialog-info="emitDialogInfo"
      />
      <!-- 權限設置 -->
      <LazySetupTeamRoleSetPermissionsDlg
        :is-show="onSetPermissionsDlg"
        :dialog-info="emitDialogInfo"
      />
      <!-- 編輯角色 -->
      <LazySetupTeamRoleEditDlg
        :is-show="onEditRole"
        :dialog-info="emitDialogInfo"
      />
      <!-- 刪除角色 -->
      <LazySetupTeamRoleDeleteDlg
        :is-show="onDelRole"
        :role-dlg-info="roleInfo"
        :role-select="transferRole"
      />
    </div>
    <!-- 角色詳情 -->
    <div v-if="isShowRole" class="detailBlock" @click.stop>
      <LazySetupTeamRoleDetail :detail-info="detailInfo" />
    </div>
  </div>
</template>

<script setup>
import { getRolesDetailFn } from '../../../api/auth/roles'
import { getDeptDetailFn } from '../../../api/auth/departments'
const { t } = useI18n()

const rolesInfo = useRolesInfo()

onBeforeMount(() => {
  rolesInfo.progressLoading = true
  getRolesList()
})

// 獲取角色列表信息 (包含座席)
const roleMap = new Map()
const rolesList = ref({})
const rolesListInfo = ref()
const getRolesList = (pageNum) => {
  const params = {
    pageNum: pageNum || 0,
    pageSize: 20
  }
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      dialogInfo.rolesList = res.data.data
      rolesList.value = res.data
      rolesListInfo.value = res.data.data
      res.data.data.forEach((i) => {
        roleMap.set(i.id, i)
      })

      if (dialogInfo.rolesList.length > 0) {
        rolesInfo.progressLoading = false
      }
    }
  }
  getRolesDetailFn(params, onRes)
}

// 獲取部門列表
const deptMap = new Map()
const getDeptList = (callback) => {
  const params = {
    pageNum: 0,
    pageSize: 20
  }
  const onResponse = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      res.data.data.forEach((i) => {
        deptMap.set(i.id, i.name)
      })
      callback()
    }
  }
  getDeptDetailFn(params, onResponse)
}

const dialogInfo = reactive({
  typeName: '',
  roleId: '',
  rolesList: []
})

// emit dialogInfo
const emitDialogInfo = ref()
const onAddAgent = ref(false) // 添加成員
const onSetPermissionsDlg = ref(false) // 權限設置
const onEditRole = ref(false) // 編輯角色

// 接收table資料
const emitInfo = (role) => {
  emitDialogInfo.value = role.roleData

  if (role.type === 'addAgent') {
    onAddAgent.value = true
  } else if (role.type === 'setPermissions') {
    onSetPermissionsDlg.value = true
  } else if (role.type === 'editRole') {
    onEditRole.value = true
  }
}

// 打開新增(編輯)角色 dialog
const onAddRole = ref(false)
const createRoleFn = () => {
  onAddRole.value = true
}

// 刪除角色 dlg
const onDelRole = ref(false)
const roleInfo = ref()
const transferRole = ref() // 轉移角色
const delRole = (info) => {
  onDelRole.value = true
  roleInfo.value = info

  // 轉移角色不可包含原本的部門
  transferRole.value = JSON.parse(JSON.stringify(rolesList.value)) // 拷貝角色
  const getSameRole = roleMap.get(info.roleId)
  const index = transferRole.value.data.findIndex(item => item.id === getSameRole.id)
  if (index !== -1) {
    transferRole.value.data.splice(index, 1)
  } else {
    ElMessage.error({ message: 'dept empty' })
  }
}

const closeDlg = () => {
  onAddRole.value = false
  onAddAgent.value = false
  onSetPermissionsDlg.value = false
  onEditRole.value = false
  onDelRole.value = false
}

// 打開【角色詳情】
const isShowRole = ref(false)
const detailInfo = ref()
const showRoleDetailBlock = (v) => {
  getDeptList(() => {
    const info = roleMap.get(v.roleId)
    if (info.agents) {
      info.agents.forEach((i) => { i.departmentName = deptMap.get(i.departmentId) }) // 比對部門id
    }
    detailInfo.value = info
    if (detailInfo.value) { isShowRole.value = true }
  })
}
const closeDetail = () => {
  if (isShowRole.value === true) {
    isShowRole.value = false
  }
}

provide('provideInfo', {
  getRolesList, // 更新角色
  rolesListInfo, // 角色list
  closeDetail, // 關閉詳情
  closeDlg // 關閉 dialog
})
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/setup.scss";
</style>
