<template>
  <v-table fixed-header style="height: calc(100vh - 283px)">
    <thead>
      <tr>
        <th v-for="item in tableHeader" :key="item.name" class="text-left" :style="item.width">
          {{ item.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="rolesInfo.progressLoading">
        <tr>
          <CommonProgressLoading />
        </tr>
      </template>
      <template v-else>
        <tr v-for="role in tableData.data" :key="role.id">
          <!-- 角色名稱 -->
          <td>
            <div class="nameBlock" @click.stop="showRoleDetail(role.id)">
              <div class="linkColor">
                {{ role.name }}
                <span v-if="role.createdBy == 10001" class="defaultText">( {{ t('setup.role.default') }} )</span>
              </div>
              <div>
                {{ role.agents.length }}
                {{ t('setup.role.memberNum') }}
              </div>
            </div>
          </td>

          <!-- 成員 -->
          <td>
            <div v-if="role.agents.length >= 1" class="userBlock">
              <div
                v-for="(agent,index) in role.agents.slice(0, 15)"
                :key="agent.id"
                class="avatarWrapper"
                :style="{'left': `-${index * 6}px`}"
              >
                <v-avatar
                  v-if="agent.avatar"
                  :image="userInfo.domain + agent.avatar"
                  size="40"
                  class="avatar"
                />
                <v-avatar v-else color="pink" size="40" class="avatar">
                  <span>{{ agent.username.substring(0, 2) }}</span>
                </v-avatar>

                <v-tooltip activator="parent" location="top">
                  <div class="text-subtitle-1 font-weight-bold">
                    {{ agent.username }}
                  </div>
                  <div style="font-size:13px;">
                    {{ agent.email }}
                  </div>
                </v-tooltip>
              </div>
              <!-- total -->
              <div
                v-if="role.agents.length && role.agents.length > 15"
                class="otherInfo"
                :style="{'left': `${40 * Math.min(role.agents.length, 15) - (6 * Math.min(role.agents.length, 15)) + 20}px`}"
              >
                <div class="me-2">
                  ...
                </div>
                <div class="totalAgentNum">
                  + {{ role.agents.length - 15 }}
                </div>
                <v-tooltip activator="parent" location="bottom">
                  <ul v-for="(agent) in role.agents.slice(15, role.agents.length)" :key="agent.id">
                    <li class="ms-3">
                      {{ agent.username }}
                    </li>
                  </ul>
                </v-tooltip>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </td>

          <!-- 操作 -->
          <td>
            <CommonMenu :id-name="`setRole-${role.id}`">
              <template #btnContent>
                <i class="fa-solid fa-ellipsis" />
              </template>
              <template #menuContent>
                <v-list class="list">
                  <v-list-item @click="emitInfoFn(role, 'addAgent')">
                    <v-list-item-title class="ms-2">
                      {{ t('setup.role.actionsSelect_add') }}
                    </v-list-item-title>
                  </v-list-item>

                  <template v-if="role.createdBy !== '10001'">
                    <v-list-item @click="emitInfoFn(role, 'setPermissions')">
                      <v-list-item-title class="ms-2">
                        {{ t('setup.role.actionsSelect_permission') }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="emitInfoFn(role, 'editRole')">
                      <v-list-item-title class="ms-2">
                        {{ t('setup.role.actionsSelect_edit') }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="delRoleFn(role)">
                      <v-list-item-title class="ms-2 colorRed">
                        <i class="fa-regular fa-trash-can me-2" />
                        {{ t('setup.role.actionsSelect_del') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </template>
            </CommonMenu>
          </td>
        </tr>
      </template>
    </tbody>
  </v-table>
  <div v-if="!rolesInfo.progressLoading" class="tableFooter">
    <div>
      {{ t('common.total') }}
      : {{ tableData.total }}
    </div>

    <!-- TODO 要寫成元件 pagination -->
    <template v-if="paginationNum > 1">
      <v-pagination
        v-model="page"
        :length="paginationNum"
        :total-visible="4"
        class="pagination"
        @click="getPage"
      />
    </template>
  </div>
</template>

<script setup>
const { t } = useI18n()

const userInfo = useUserInfo()
const rolesInfo = useRolesInfo()

const prop = defineProps({
  tableData: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['emitInfo', 'delRole', 'showRoleDetailBlock'])
const { getRolesList } = inject('provideInfo')

// table
const tableHeader = computed(() => [
  {
    name: t('setup.role.tableHeader_name'), // 角色名稱
    width: 'min-width:250px;'
  },
  {
    name: t('setup.role.tableHeader_user'), // 成員
    width: 'min-width:600px; max-width:1052px;'
  },
  {
    name: t('common.tableHeaderActions'), // 操作
    width: 'min-width:120px;'
  }
])

// 切分頁 ---
const page = ref(1)
const getPage = () => {
  getRolesList(page.value - 1)
}
const paginationNum = computed(() => {
  if (prop.tableData.total === null) { return '' }

  const num = Math.floor(prop.tableData.total / 20) // 目前顯是筆數固定20
  if (num < 0) {
    return 1
  } else {
    const remainder = Math.floor(prop.tableData.total % 20)
    return remainder ? num + 1 : num
  }
})

// role info (添加成員、權限設置、編輯角色)
const emitInfoFn = (role, typeValue) => {
  emit('emitInfo', {
    roleData: role,
    type: typeValue
  })
}

// 刪除角色
const delRoleFn = (role) => {
  emit('delRole', {
    roleId: role.id,
    roleName: role.name,
    agentNum: role.agents.length
  })
}
// 顯示角色細節
const showRoleDetail = (id) => {
  emit('showRoleDetailBlock', {
    roleId: id,
    isShowDept: true
  })
}
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/setup.scss";
</style>
