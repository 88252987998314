import { defineStore } from 'pinia'
import { getUsersListFn } from '@/api/auth/users'

export const useRolesInfo = defineStore('rolesInfo', {
  state: () => ({
    progressLoading: false,
    getAgentLoading: false,
    agentList: []
  }),
  getters: {

  },
  actions: {
    // 獲取用戶列表
    async getAgentList (params, currentRoleId, join, joined) {
      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSizeNum || 20
      }

      const res = await getUsersListFn(params)
      const data = res.data.value
      if (data.code === 200) {
        const result = data.data.data

        // 判斷加入或已加入
        result.forEach((agent, index) => {
          result[index].joinRole = {
            state: currentRoleId === agent.roleId,
            stateClass: currentRoleId === agent.roleId ? 'join' : 'noJoin',
            btnText: currentRoleId === agent.roleId ? joined : join
          }
        })

        // 排序
        const noJoinArray = result.filter(e => e.joinRole.state === false)
        const joinArray = result.filter(e => e.joinRole.state === true)
        const sortArray = noJoinArray.concat(joinArray)

        this.agentList = sortArray
        this.getAgentLoading = false
      }
    }
  }
})
